// taken from https://raw.githubusercontent.com/alexradulescu/FreezeUIand
// and modified
(function () {
    var freezeHtml = document.createElement('div');

     freezeHtml.innerHTML = `<div id="splashScreen" style="visibility: visible;">
    <div class="dynamics-loader">
    <div>
    <svg class="empower-logo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1500 1500" style="enable-background:new 0 0 1500 1500;" xml:space="preserve">
	 <style type="text/css">
	 .st0{fill:#1F2640;}
	 .st1{fill:#0565F9;}
	  .st2{fill:none;}
	 .st3{fill:#DEDEDE;}
	 .st4{fill:#4E4E4E;}
	 .st5{fill:#20B4DB;}
 </style>
 <path class="st0" d="M274.2,688.5c29.3-41.3,84.2-32,100.2,1c4.6-4.5,9-9.3,13.8-13.5c24.6-21.2,65.9-20,86.3,2.5  c8.5,9.4,12.9,20.8,13.9,33.1c2.7,33.8,0.8,67.7,1.1,101.5c0.1,12-8.5,19.9-19.8,19.5C458.6,832.3,451,824,451,812  c-0.1-25.2,0-50.4,0-75.6c0-5.7-0.3-11.4-1.9-16.9c-4.7-16.1-16-24.1-33.4-23.8c-15.2,0.2-28,10.5-32.1,25.7c-1.6,6-1.7,12-1.7,18.1  c0,24,0,48.1,0,72.1c0,11.7-6.5,19.7-16.5,21c-11.9,1.6-21.9-7.3-21.9-19.8c-0.1-26.2-0.1-52.4,0-78.6c0-5-0.6-9.8-2-14.5  c-4.6-16.1-15.8-24.2-32.6-24.1c-15.3,0.1-28.5,10.4-32.7,25.7c-1.7,6.1-2.1,12.4-2.1,18.7c0,24.2,0,48.5,0,72.7  c0,8.8-3.8,15.1-11.9,18.6c-7.2,3.1-15.8,1.1-21.1-4.5c-3.4-3.6-5.2-7.8-5.2-12.8c0-44.1-0.1-88.2,0-132.4  c0-10.7,8.1-18.9,18.4-19.2c10.4-0.2,18.7,7.3,19.7,17.9C274,682.7,274,685,274.2,688.5z"/>
 <path class="st0" d="M1035,720.2c-5.6,16.8-11.2,33.6-16.8,50.4c-5.2,15.8-10.4,31.6-15.6,47.3c-3.5,10.4-10.5,15.8-20.2,15.9  c-11,0.2-18.2-4.5-22-15.2c-15.4-43.1-30.6-86.3-45.8-129.5c-3.7-10.4-1.1-18.7,6.4-23.5c7.1-4.6,16.1-4.1,22.5,1.3  c3.9,3.3,5.8,7.8,7.3,12.5c10.3,32.5,20.7,64.9,31.1,97.5c2.7-1.3,2.3-4,3-6c9.9-30.5,19.7-61.1,29.6-91.6  c3.7-11.6,10.5-16.7,21.3-16.7c10.7,0,17.5,5.4,21.2,16.9c9.9,30.7,19.9,61.4,29.9,92.1c0.6,1.8,0.6,3.9,2.8,5.5  c7.7-23.9,15.3-47.5,22.9-71.2c3.2-10.1,6.2-20.2,9.7-30.2c3.2-9.2,11-14,20.1-13.1c8,0.8,14,6.6,15.8,15.1  c0.8,3.8-0.3,7.5-1.5,10.9c-15.1,43-30.3,86-45.6,129c-4.2,11.6-11.6,16.5-23.1,16.2c-9.9-0.2-16.6-5.9-20.3-17.4  c-10.3-31.2-20.5-62.5-30.7-93.7C1036.6,721.7,1036.4,720.8,1035,720.2z"/>
 <path class="st0" d="M556,692.3c9.9-13.2,20.5-22.9,34.8-27.8c28.2-9.6,61.4,0.4,80.5,24.2c26,32.3,25.7,86.4-0.4,118.4  c-27.1,33.2-81.7,39.5-112.3,1.2c-0.6-0.7-1.2-1.4-2.6-3c0,5.2,0,9.2,0,13.3c0,15.4,0.1,30.7,0,46.1c-0.1,10.4-8.3,18.6-18.8,18.7  c-10.3,0.1-19-8.1-19-18.5c-0.1-61.2-0.1-122.5,0-183.7c0-10.6,8.4-18.6,18.8-18.6c10.7,0,18.9,8.2,19,19  C556,684.6,556,687.7,556,692.3z M603.9,694.2c-8.7-0.3-16.6,2.1-23.9,6.5c-20.3,12.4-29.8,38-22.5,63.5c6.1,21.5,20.6,34.9,43.1,37  c17.9,1.6,32.7-6,42.4-21.3c8.7-13.7,10.4-29,7.5-44.8C646,711.1,626.9,693.8,603.9,694.2z"/>
 <path class="st1" d="M861.1,778.8c17.6,7.4,33,17,46.5,29.4c2.7,2.5,5.7,4.9,7.3,8.4c3.1,6.6,1.1,14.3-4.6,18.5  c-5.9,4.3-13.8,4-19.4-1.5c-10.7-10.3-22.7-18.7-36.2-24.8c-42.9-19.6-96.2-10.9-130.5,21.4c-3.1,2.9-6,6.3-10.5,7.3  c-6.9,1.6-13.3-1.1-16.7-6.9c-3.5-6-2.6-13.1,2.6-18.4c12.8-13,27.7-23.1,44.3-30.6c1.9-0.9,3.8-1.8,5.8-2.8  c-45.5-39.5-38.7-99.7-7-131.6c32-32.2,85.1-34.9,119.4-5.5c21.1,18,31.9,40.9,31.8,68.7C893.8,738.2,882.1,760.5,861.1,778.8z   M806,768c32.4-0.2,58.6-26.7,58.3-58.7c-0.3-32.5-26.7-58.1-59.6-57.9c-32.1,0.2-57.7,26.6-57.5,59.2  C747.5,742.4,774,768.3,806,768z"/>
 <path class="st0" d="M145.9,761.6c-16.3,0-32.7,0.1-49-0.1c-4.1-0.1-5.1,1.1-4.2,4.9c5.3,22.4,23.5,36.8,45.4,36.9  c15.3,0.1,28.4-4.3,40.3-13.5c8-6.2,16.8-5.6,22.5,1.4c5.4,6.5,4.1,16.4-2.9,22.6c-14,12.4-30.6,19.2-49,20.7  c-28.4,2.2-53.9-5-73.6-26.8c-18.4-20.4-24.1-44.7-21-71.6c2.2-19.5,9.5-36.8,23.3-50.9c22.5-23,50.1-29.3,80.5-21  c29.4,8,46,29.2,53.6,57.9c1.7,6.2,2.6,12.7,2.5,19.2c-0.1,12.7-7.4,20.2-20,20.2C178.2,761.6,162,761.6,145.9,761.6z M91.5,735.8  c2.5,0,4.6,0,6.8,0c23,0,46,0,69.1,0c10.4,0,10.4,0,8-10c-6.2-25.6-29.7-39.7-53.7-31.4C102.5,701,94.9,716.7,91.5,735.8z"/>
 <path class="st0" d="M1256.9,761.6c-16.1,0-32.3,0.2-48.4-0.1c-4.9-0.1-5.7,1.5-4.7,5.6c6.2,23.5,24.7,36.4,46,36.3  c15.1,0,28-4.4,39.7-13.5c7.9-6.2,16.7-5.6,22.5,1.4c5.5,6.6,4.2,16.3-3,22.6c-13.8,12.2-30.2,19.1-48.4,20.6  c-28.6,2.4-54.3-4.8-74.2-26.8c-18.6-20.5-24.2-45.2-20.9-72.2c2.9-23.9,13.2-43.9,32.6-58.6c40.8-31.1,110.2-17.9,124.9,45.9  c1.5,6.5,2.6,13.1,2.5,19.8c-0.2,11-7.9,18.8-18.9,18.9C1290,761.7,1273.4,761.6,1256.9,761.6z M1202.6,735.8c25.6,0,51,0,76.3,0  c9.9,0,10.1,0,7.4-9.9c-4.2-15.3-13.8-31.6-37.3-33.6c-14.9-1.2-29.6,4.9-39,21.1C1206.1,720,1203.8,727.3,1202.6,735.8z"/>
 <path class="st0" d="M1386.1,695.8c4.2-7.9,8.9-15.6,15.5-21.8c7.5-7.1,15.9-12.1,26.7-11.6c9.4,0.4,16.2,6.3,17.6,15.8  c1.3,9.2-3.7,17.7-12.2,20.5c-3,1-6.1,1.4-9.1,2.3c-22.8,6.8-37.3,26.4-39,51.2c-1.4,20.7-0.2,41.3-0.9,62  c-0.4,13.5-13.1,21.9-25.7,17.3c-7.1-2.6-12.2-9.2-12.2-17.2c-0.1-44.5-0.2-89,0-133.5c0-10.4,8.5-18.2,18.6-18.3  c10.5-0.1,18.8,7.5,19.3,18.1c0.2,4.9,0,9.8,0,14.7C1385.2,695.5,1385.6,695.6,1386.1,695.8z"/>
 <path class="st2" d="M603.9,694.2c23-0.4,42.1,16.9,46.6,40.9c2.9,15.8,1.2,31-7.5,44.8c-9.8,15.4-24.5,23-42.4,21.3  c-22.5-2-37-15.5-43.1-37c-7.3-25.6,2.2-51.2,22.5-63.5C587.3,696.3,595.2,694,603.9,694.2z"/>
 <path class="st2" d="M806,768c-32.1,0.2-58.5-25.6-58.8-57.4c-0.2-32.7,25.3-59,57.5-59.2c32.9-0.2,59.3,25.4,59.6,57.9  C864.7,741.3,838.4,767.8,806,768z"/>
 <path class="st2" d="M91.5,735.8c3.4-19.1,11-34.8,30.1-41.3c24.1-8.3,47.6,5.8,53.7,31.4c2.4,10,2.4,10-8,10c-23,0-46.1,0-69.1,0  C96.1,735.8,94,735.8,91.5,735.8z"/>
 <path class="st2" d="M1202.6,735.8c1.2-8.5,3.4-15.7,7.3-22.3c9.5-16.1,24.1-22.3,39-21.1c23.6,2,33.2,18.2,37.3,33.6  c2.7,9.8,2.5,9.9-7.4,9.9C1253.6,735.8,1228.3,735.8,1202.6,735.8z"/>
 <g>
	 <path class="st3" d="M576.6,1323.2c-3.7,0.5-7,2-10.1,3.9c-1.9,1.2-3.2,0.3-4.6-0.7h0c1-1.9,2.7-3,4.6-4   c17.8-9.5,36.1-17.9,55.3-24.1c13-4.1,26.3-7.3,39.7-9.5c2.1-0.3,2.9-0.8,3.1-3c0.2-2,0.9-4,1.5-6c3.8-14.1,12.6-24.1,25.1-31.2   c8-4.6,16.5-6.6,25.5-7.9c13.8-1.9,26.4,1.2,38.5,7.4c3.1,1.6,6.5,3.1,8.4,6.4l0,0c-2,0.3-3.8-0.6-5.3-1.6   c-8.1-5.5-17.1-7.3-26.7-7.6c-9.7-0.3-19.2,0.7-28.1,4.7c-13.9,6.3-23.8,16.3-28.3,31.2c-0.3,0.9-0.3,1.9-0.3,2.8   c-0.1,1.6,0.6,2.5,2.4,2.2c8.3-1.5,16.9-1.3,25.2-2.3c11.2-1.3,22.3-1.2,33.4-0.7c7.8,0.4,15.7,0.9,23.6,1.7   c9,0.9,17.8,2.4,26.7,3.8c6.8,1.1,13.6,2.6,20.3,4.2c11,2.5,21.7,6,32.2,9.7c6,2.1,12.1,4.2,17.7,7.3c3.1,1.7,6.3,3.3,9.8,4.4   c1.8,0.6,3.4,1.5,3.4,4c0.1,3-0.7,3.8-3.6,2.7c-7-2.8-14.1-5.1-21.2-7.3c-13.7-4.4-27.7-7.8-41.9-10.4c-11-2.1-22.2-3.6-33.2-5.1   c-6.6-0.9-13.4-1.5-20.3-1.6c-11.3-0.1-22.7-2.3-33.9-0.8c-9.9,1.3-20-0.2-29.8,1.9c-4.4,0.9-9,0.7-13.5,1.1   c-6.6,0.5-12.9,2.2-19.5,2.8c-9.2,0.9-18.4,3.3-27.4,5.5c-9.7,2.4-19.7,4.2-28.8,8.7C588.8,1315.8,582.7,1319.5,576.6,1323.2z"/>
	 <path class="st4" d="M989,1325.9c0,10.8,0,21.6,0,32.4c0,2.2-0.2,3.5-3,3.5c-3,0-2.8-1.7-2.8-3.7c0-12.1,0-24.3,0.1-36.4   c0-1.5-1.1-4,1.3-4.3c2.4-0.3,5.3-1.4,7.3,1.3c0.7,0.9,1.3,1.8,2,2.8c7.1,9.6,14.1,19.2,21.2,28.8c0.5,0.7,0.9,1.6,2.6,1.9   c0-7.4,0-14.7,0-21.9c0-3.2,0.1-6.4,0-9.7c-0.1-2.2,0.3-3.5,3-3.5c2.7,0,3.6,0.7,3.6,3.5c-0.1,11.9,0,23.9-0.1,35.8   c0,1.7,1.2,3.9-1.2,5c-2.3,1-6.4-0.1-8.1-2.2c-3.4-4.5-6.8-9.1-10.2-13.6c-4.9-6.7-9.9-13.3-14.8-20   C989.6,1325.8,989.3,1325.8,989,1325.9z"/>
	 <path class="st4" d="M737.2,1339c-0.2,12.8-8.2,23.9-25.5,23.2c-6.8-0.3-12.8-2.1-17.7-7.4c-10-10.8-6-34.8,12.9-37.8   c5.7-0.9,10.9-0.8,16.1,0.9C731.7,1320.7,737.2,1329.1,737.2,1339z M730.6,1339.6c0-11.6-6.3-18.3-17.4-18.3   c-10.9,0-17.5,7-17.9,18.1c-0.4,10.9,8.4,18.3,17.3,18.1C723.9,1357.2,730.6,1350.9,730.6,1339.6z"/>
	 <path class="st4" d="M782.9,1354c0-11.7,0-22.4,0-33.2c0-1.6-0.5-3.4,2.3-3.6c2.6-0.1,3.6,0.6,3.6,3.4c-0.2,12.1,0,24.3-0.1,36.4   c0,1.5,1,3.5-1.1,4.6c-1.9,1-6.2-0.2-7.6-2c-3.4-4.5-6.8-9.1-10.2-13.6c-5.1-6.8-10.1-13.6-15.6-21c-1,1.7-0.7,3-0.7,4.1   c0,9.7,0,19.3,0,29c0,2,0.1,3.8-2.8,3.7c-2-0.1-3.2-0.4-3.1-2.8c0.2-4.9,0-9.9,0-14.8c0-7.8,0.1-15.5,0-23.3   c-0.1-2.6,0.6-3.7,3.4-3.7c3,0,5.1,0.6,7.1,3.4c7.3,10.4,15,20.5,22.5,30.7C781.2,1352,781.8,1352.6,782.9,1354z"/>
	 <path class="st5" d="M576.6,1323.2c6.1-3.8,12.2-7.4,19.7-7.3c5.9,0.4,11.4,1.8,16.2,5.7c9.9,8.1,10,28.1-1.3,35.9   c-0.4,0.3-0.7,0.8-1.2,1.4c3.5,3.3,8.3,4.6,12,8.5c-4.5,0.4-8.5,0.7-12-2.2c-3.8-3.2-8.1-3.6-13.1-3.3c-10,0.7-18.9-1.2-24-13.7   c-3.3-7.9-2.1-15.8,2.7-23.1C576,1324.6,576.3,1323.9,576.6,1323.2z M614.5,1339.6c0.1-11.6-6.7-18.8-17.8-19   c-12.4-0.1-19.7,6.8-19.8,18.7c-0.1,11.4,7.1,18.8,18.6,18.9C608.5,1358.3,614.7,1348.9,614.5,1339.6z"/>
	 <path class="st4" d="M1074.7,1324.3c-6.1-2.7-12-3.4-18.1-2.6c-9.7,1.2-15.9,8.6-15.5,18.8c0.3,9.7,6.7,16.3,16.3,16.7   c3.6,0.2,7.1,0.2,10.6-1.1c2.1-0.7,2-2.1,1.8-3.5c-0.3-3.1,1.4-6.8-1.1-9.1c-1.3-1.2-4.4-0.3-6.6-0.3c-1.8,0-3.4,0-3.4-2.6   c0-2.5,1.4-2.7,3.3-2.6c3.6,0.1,7.2,0.1,10.8,0c2.5-0.1,3.3,0.9,3.2,3.3c-0.1,4.9,0,9.8,0,14.8c0,1.4-0.1,2.6-1.7,3.2   c-9.5,3.4-19.2,4.5-28.6,0c-13.9-6.7-15.7-27.7-2.4-37.5c7-5.1,15-6.9,23.6-5c1.1,0.2,2.2,0.3,3.4,0.5   C1075.1,1318.1,1075.3,1318.4,1074.7,1324.3z"/>
	 <path class="st5" d="M562,1326.5c-0.4,1.6,0.2,3,0.9,4.3c3.5,7.6,0.4,20.6-7,26.6c-4.6,3.8-9.8,4.6-15.2,4.8   c-9.7,0.4-17.8-2.9-22.6-11.8c-7.9-14.6,1.7-32.4,17.8-34.1c9-0.9,17.3,0.5,23.6,7.7C560.2,1324.9,560.2,1326.6,562,1326.5   L562,1326.5z M521.1,1339.2c-0.5,10.8,6.7,19.1,18.5,19c12.7-0.2,18.8-7,19-19c0.2-9.8-5.6-18.6-18.6-18.8   C528.6,1320.2,520.2,1330.1,521.1,1339.2z"/>
	 <path class="st5" d="M448.5,1316.1c15.4-0.7,25.5,11.1,24.5,23.8c-0.7,9.2-4.8,16.5-13.5,20.7c-3,1.5-6.3,1.4-9.4,1.5   c-8.8,0.4-16.9-1.5-22-9.6c-5-7.9-5.8-16.4-1.1-24.5C431.6,1319.7,439.2,1315.5,448.5,1316.1z M449.2,1320.6   c-11.3-1.2-19.5,8.7-19.4,18.8c0.1,10.8,6.8,19.1,18.6,18.8c12.7-0.3,18.7-6.6,18.9-19.1C467.6,1327,459.7,1320,449.2,1320.6z"/>
	 <path class="st4" d="M873.4,1333.5c0-4.5,0.1-9.1,0-13.6c-0.1-2.5,1.4-2.7,3.3-2.6c1.9,0,3.2,0.3,3.1,2.8   c-0.1,8.7,0.6,17.5-0.2,26.1c-1.2,12.2-7.3,16.2-20.3,16c-7.2-0.1-13.5-2-16.4-9.5c-1.2-3-1.2-6.3-1.2-9.5   c-0.1-7.2,0-14.4-0.1-21.6c0-2.3-0.3-4.4,3.3-4.4c3.4,0.1,2.9,2.1,2.9,4.1c0,7.8,0.1,15.5,0,23.3c0,3.1,0.6,6,2.5,8.5   c3,3.9,10.1,5.8,15.6,3.8c4.7-1.7,7.3-5.2,7.4-10.3c0.1-4.3,0-8.7,0-13.1C873.3,1333.5,873.3,1333.5,873.4,1333.5z"/>
	 <path class="st4" d="M800.8,1354.1c4.9,2.2,9.9,3.8,15.4,3.3c1.5-0.1,3-0.1,4.4-0.7c5.1-2.2,6-7.1,1.8-10.8   c-2.5-2.1-5.5-3.2-8.4-4.6c-2.6-1.2-5.3-2.2-7.6-3.7c-3.6-2.4-6.4-5.5-5.8-10.3c0.6-5,3.9-7.7,8.2-9.2c6-2.1,12.1-2.5,18.4-0.7   c4,1.1,1.3,4,2.2,6c-5.3-1.6-10.3-2.3-15.4-1.5c-4,0.6-6.7,2.6-7,5.6c-0.3,3.6,2.1,5.5,4.9,6.9c2.2,1.1,4.4,2.1,6.7,3.1   c2.5,1,4.8,2.3,7,3.7c4.1,2.7,6.6,6.2,5.7,11.4c-0.9,5.2-4.7,7.7-9.2,8.9c-5.7,1.4-11.7,1.1-17.4,0   C800.2,1360.5,800.3,1360.2,800.8,1354.1z"/>
	 <path class="st4" d="M682,1354.6c-0.5,2.8,0.9,5.3-2.1,6c-10,2.3-19.9,3.4-28.6-3.7c-7.5-6.2-8.5-14.6-6.4-23.3   c2.1-8.7,8.4-13.6,16.8-16.1c5.7-1.7,11.5-1.6,17.3-0.2c2,0.5,2.9,1.2,2.9,3.3c0,2-0.6,3-2.7,2.2c-4.4-1.6-8.8-1.7-13.4-1.1   c-9.6,1.2-15.6,8.7-15.2,19.2c0.3,9.4,6.7,15.9,16.1,16.5C671.7,1357.6,676.5,1357.2,682,1354.6z"/>
	 <path class="st5" d="M482.9,1339.2c0-5.9,0.1-11.8,0-17.6c-0.1-3,1-4.1,4.2-4.6c6.1-0.8,12,0.4,18.1,0c1.3-0.1,3.4-0.1,3.3,2.2   c0,2.1-1.8,2.2-3.3,2.2c-4.2-0.1-8.3-0.3-12.5-0.3c-4,0.1-4.4,0.6-4.5,4.7c0,2.3,0,4.6,0,6.8c0.1,4.5,0.1,4.5,4.8,4.6   c2.8,0,5.7,0,8.5,0c1.1,0,2.3,0.1,3.4,0.3c1.1,0.2,2.2,0.7,2.1,2.2c-0.1,1.6-1.3,1.6-2.5,1.6c-3.8,0-7.6,0-11.4,0   c-5.7,0-5.1-0.1-5.1,5.4c0,3.8,0,7.6,0,11.4c0,1.6,0.5,3.6-2.2,3.7c-2.9,0.2-3-2-3-3.8c-0.2-5.5-0.1-11-0.1-16.5   C482.9,1340.7,482.9,1340,482.9,1339.2C482.9,1339.2,482.9,1339.2,482.9,1339.2z"/>
	 <path class="st4" d="M892.9,1338.9c0-6.1,0-12.1,0-18.2c0-2.1,0.1-3.5,2.9-3.6c3,0,3.3,1.3,3.3,3.8c-0.1,9.8-0.1,19.7,0,29.5   c0,7.3-0.9,6.4,6.2,6.4c3.8,0,7.6,0,11.4,0c1.6,0,3,0,3,2.3c0,2.1-1,2.5-2.8,2.5c-7-0.1-14-0.1-21,0c-2.2,0-3-0.7-3-3   C893,1352.2,892.9,1345.5,892.9,1338.9C892.9,1338.9,892.9,1338.9,892.9,1338.9z"/>
	 <path class="st4" d="M933.3,1341.2c0-5.5-0.1-11,0-16.5c0.1-2.3-0.8-3-3-2.9c-3.2,0.1-6.4,0-9.7,0c-1.7,0-2.3-0.7-2.3-2.4   c0-1.8,0.8-2.2,2.4-2.2c10.2,0,20.5,0,30.7,0c1.5,0,2.4,0.3,2.5,2.2c0.1,2.2-1.1,2.5-2.8,2.4c-2.8-0.1-5.7,0.2-8.5,0   c-2.8-0.2-3.6,0.9-3.5,3.6c0.1,10.8,0.1,21.6,0,32.4c0,1.5,0.9,3.5-1.9,3.9c-3.1,0.4-4-0.2-4-3.5   C933.2,1352.6,933.3,1346.9,933.3,1341.2z"/>
	 <path class="st4" d="M962.7,1339.5c0-6.2,0-12.5,0-18.7c0-2,0-3.6,2.9-3.6c2.5,0.1,3.3,0.8,3.2,3.3c-0.1,12.7-0.1,25.4,0,38.1   c0,2.4-0.7,3.3-3.2,3.3c-2.9,0-2.9-1.5-2.9-3.6C962.7,1352,962.7,1345.7,962.7,1339.5z"/>
	 <path class="st3" d="M888.3,1330.6c-2,0.1-3.5-1.2-5.1-2c-1.5-0.8-1.3-2.3-0.8-3.5c0.5-1.3,1.8-1,2.9-0.5   C887.9,1325.7,889.9,1327.2,888.3,1330.6z"/>
	 <path class="st3" d="M763.7,1254.7c0.8,0.1,1.9,0,1.9,1.3c0,0.2-0.4,0.6-0.7,0.6C763.6,1256.6,763.7,1255.6,763.7,1254.7   L763.7,1254.7z"/>
	 <path class="st2" d="M730.6,1339.6c0,11.3-6.7,17.6-18,17.9c-8.9,0.2-17.8-7.2-17.3-18.1c0.5-11.1,7-18.2,17.9-18.1   C724.3,1321.3,730.6,1328,730.6,1339.6z"/>
	 <path class="st2" d="M538.3,1342.4c-0.5,0.1-1.1,0.1-1.6,0.3c-0.3,0.1-0.1,0.6,0.4,0.6C537.7,1343.3,538.1,1342.9,538.3,1342.4   c0.2-0.2,1.1,0,0.4-0.8C538.6,1341.8,538.4,1342.1,538.3,1342.4z M541.7,1340.1c0.8-0.1,1.9,0.1,1.9-1.3c0-0.2-0.4-0.6-0.7-0.6   C541.6,1338.2,541.8,1339.2,541.7,1340.1c-0.2,0.2-1.1,0-0.5,0.8C541.4,1340.6,541.5,1340.3,541.7,1340.1z M554.9,1331.8   c-4.3-0.3-6.8,2.5-9.3,5.4C549.3,1336.7,552.3,1334.6,554.9,1331.8z"/>
	 <path class="st2" d="M449.2,1320.6c10.5-0.6,18.4,6.4,18.2,18.5c-0.2,12.5-6.2,18.8-18.9,19.1c-11.8,0.2-18.5-8-18.6-18.8   C429.7,1329.3,437.8,1319.4,449.2,1320.6z"/>
	 <path class="st3" d="M554.9,1331.8c-2.6,2.9-5.5,4.9-9.3,5.4C548.1,1334.3,550.6,1331.4,554.9,1331.8z"/>
	 <path class="st3" d="M541.7,1340.1c0.1-0.8-0.1-1.9,1.3-1.9c0.2,0,0.7,0.4,0.7,0.6C543.6,1340.1,542.5,1339.9,541.7,1340.1   L541.7,1340.1z"/>
	 <path class="st3" d="M538.2,1342.3c-0.2,0.6-0.5,1-1.1,1c-0.4,0-0.6-0.5-0.4-0.6C537.2,1342.5,537.7,1342.5,538.2,1342.3   L538.2,1342.3z"/>
	 <path class="st3" d="M541.7,1340.1c-0.2,0.3-0.3,0.5-0.5,0.8C540.5,1340.1,541.4,1340.3,541.7,1340.1L541.7,1340.1z"/>
	 <path class="st3" d="M538.3,1342.4c0.1-0.3,0.3-0.5,0.4-0.8C539.4,1342.3,538.5,1342.1,538.3,1342.4L538.3,1342.4z"/>
 </g>
 </svg>
  
    </div>
    </div>
</div>`;

    var busyLoader = document.createElement('div');
    busyLoader.innerHTML = `<div id="d365BusyLoader" style="visibility: visible;" class="d365-popup">
        <span>
            Pleas wait, We're processing your request.
        </span>
    </div>`;

    var freezedItems = [];

    function getSelector(selector) {
        return selector ? selector : 'body';
    }

    function shouldFreezeItem(selector) {
        var itemSelector = getSelector(selector);
        return freezedItems.indexOf(itemSelector) >= 0;
    }

    function addFreezedItem(selector) {
        var itemSelector = getSelector(selector);
        freezedItems.push(itemSelector);
    }

    function removeFreezedItem(selector) {
        var itemSelector = getSelector(selector);
        for (var i = 0; i < freezedItems.length; i++) {
            if (freezedItems[i] === itemSelector) {
                freezedItems.splice(i, 1);
            }
        }
    }

    function normalizeFreezeDelay(delay) {
        return delay ? delay : 1000;
    }

    window.FreezeD365UI = function (options) {
        if (!options) {
            options = {};
        }

        addFreezedItem(options.selector);
        var freezeDelay = normalizeFreezeDelay(options.freezeDelay);

        setTimeout(function () {
            if (!shouldFreezeItem(options.selector)) {
                return;
            }

            var parent;

            if (options.element) {
                parent = options.element;
            } else {
                parent = document.querySelector(options.selector) || document.body;
            }

            freezeHtml.setAttribute('data-text', options.text || 'Loading');

            if (document.querySelector(options.selector) || options.element) {
                freezeHtml.style.position = 'absolute';
            }

            parent.appendChild(freezeHtml);
        }, freezeDelay);
    };

    window.UnFreezeD365UI = function (options) {
        if (!options) {
            options = {};
        }

        removeFreezedItem(options.selector);
        var freezeDelay = normalizeFreezeDelay(options.freezeDelay) + 250;

        setTimeout(function () {

            var freezeHtml;
            freezeHtml = document.querySelector('#splashScreen');

            if (freezeHtml) {
                if (freezeHtml.parentElement) {
                    freezeHtml.parentElement.removeChild(freezeHtml);
                }
            }
        }, freezeDelay);
    };

    window.BusyD365UI = function (options) {
        if (!options) {
            options = {};
        }


            var parent;
            //busyLoader = document.createElement('div');
            busyLoader.innerHTML = `<div id="d365BusyLoader" style="visibility: visible;" class="d365-popup">
                <span>
                    Please wait, We're processing your request.
                </span>
            </div>`;
            if (options.element) {
                parent = options.element;
            } else {
                parent = document.querySelector(options.selector) || document.body;
            }

            if (document.querySelector(options.selector) || options.element) {
                busyLoader.style.position = 'absolute';
            }

            parent.appendChild(busyLoader);

    };

    window.FreeeD365UI = function (options) {
        if (!options) {
            options = {};
        }

            var busyLoader;
            busyLoader = document.querySelector('#d365BusyLoader');

            if (busyLoader) {
                if (busyLoader.parentElement) {
                    busyLoader.parentElement.removeChild(busyLoader);
                }
            }

    };
})();



